<script>
import CommentEditable from '@comp/document/comments/CommentEditable.vue'
import CommentImagePopup from '@comp/document/comments/CommentImagePopup.vue'
import CommentReadOnly from '@comp/document/comments/CommentReadOnly.vue'
import { useIndexStore } from '@src/store/index.js'
import {
  createComment,
  deleteInitialComment,
  updateInitialComment,
  updatePmComment,
} from './queries'

export default {
  name: 'Comments',
  components: {
    CommentReadOnly,
    CommentEditable,
    CommentImagePopup,
  },
  props: {
    allComments: {
      type: Array,
      required: true,
    },
    threadImportance: {
      type: String,
      default: '',
    },
    threadId: {
      type: String,
      default: () => undefined,
    },
    documentVersionId: {
      type: String,
      required: true,
    },
    canAddComment: {
      type: Boolean,
      required: true,
    },
    currentStep: {
      type: String,
      required: true,
    },
    isOmanType: {
      type: Boolean,
      required: true,
    },
    pdfPreview: {
      type: Boolean,
      required: true,
    },
    commentedPage: {
      type: Number,
      required: true,
    },
    isCommentedPageClicked: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'update:allComments',
    'switchToCommentEdition',
    'switchToCommentsListing',
    'closeDialog',
    'newThread',
    'threadDeleted',
    'cancelCommentEdition',
    'activateLink',
  ],
  data () {
    return {
      comments: [],
      editableCommentIndex: null,
      currentCommentIndex: null,
      showImagePopup: false,
      newCommentAdded: false,
      isEditionMode: false,
    }
  },
  computed: {
    store () {
      return useIndexStore()
    },
    canSeeEditableComment () {
      return this.canAddComment
    },
    commentsHeader () {
      return `${this.$gettext('Thread comments')} / ${this.$gettext(this.formattedThreadImportance)}`
    },
    formattedThreadImportance () {
      switch (this.threadImportance) {
        case 'MINOR':
          return 'Minor'
        case 'MAJOR':
          return 'Major'
        case 'INTERNAL':
          return 'Internal'
        default:
          return ''
      }
    },
    isNewThread () {
      return this.threadId === undefined
    },
    editableComment: {
      get () {
        if (this.editableCommentIndex === null) {
          return null
        } else if (this.editableCommentIndex === -1) {
          return {
            text: '',
            imageUrl: '',
            permissions: {
              canEdit: true,
              canDelete: true,
            },
            documentVersion: {
              index: null,
            },
            __typename: 'InitialComment',
          }
        } else {
          return this.comments[this.editableCommentIndex]
        }
      },
      set (comment) {
        if (this.editableCommentIndex >= 0) {
          const newComments = this.comments.slice()
          newComments[this.editableCommentIndex] = comment
          this.comments = newComments
        }
      },
    },
    editableCommentShown () {
      return !!this.editableComment
    },
    currentComment: {
      get () {
        return this.currentCommentIndex === null ? null : this.comments[this.currentCommentIndex]
      },
      set (comment) {
        if (comment === null) {
          this.currentCommentIndex = null
        } else {
          const commentId = comment.id
          this.currentCommentIndex = this.comments.findIndex(comment => comment.id === commentId)
        }
      },
    },
  },
  watch: {
    allComments: {
      handler () {
        this.comments = this.allComments.map(comment => ({ ...comment }))
        this.computeEditableCommentIndex()
      },
      immediate: true,
    },
    comments (value, oldValue) {
      if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
        this.computeEditableCommentIndex()
        this.$emit('update:allComments', this.comments)
      }
    },
    isNewThread: {
      handler () {
        if (this.isNewThread) {
          this.onAddClick()
        }
      },
      immediate: true,
    },
  },
  methods: {
    computeEditableCommentIndex () {
      const editableComments = this.comments.filter(this.isEditable)
      if (editableComments.length) {
        this.editableCommentIndex = this.comments.findIndex(comment => comment.id === editableComments[0].id)
      } else if (this.newCommentAdded) {
        this.editableCommentIndex = -1
      } else {
        this.editableCommentIndex = null
      }
    },
    isEditable (comment) {
      return comment.permissions.canEdit
    },
    isReadOnly (comment) {
      return !this.isEditable(comment)
    },
    showImage (comment) {
      this.currentComment = comment
      this.showImagePopup = true
    },
    closeImage () {
      this.showImagePopup = false
      this.currentComment = null
    },
    startEdit (comment, ...params) {
      this.isEditionMode = true
      this.$emit('switchToCommentEdition', ...params)
    },
    cancelEdit (comment) {
      if (this.newCommentAdded) {
        this.isEditionMode = false
        this.newCommentAdded = false
        this.computeEditableCommentIndex()
      }
      this.$emit('cancelCommentEdition')
      this.closeEdit(comment)
    },
    closeEdit () {
      this.$emit('closeDialog')
    },
    onAddClick () {
      this.isEditionMode = true
      this.newCommentAdded = true
      this.computeEditableCommentIndex()
    },
    async onSaveEdit (comment, oldComment, importance, commentedPage) {
      if (this.isNewThread) {
        this.$emit('newThread', comment, importance, commentedPage)
      } else if (comment.id) {
        await this.updateComment(comment, oldComment)
      } else {
        await this.createComment(comment, commentedPage)
      }
      this.$emit('cancelCommentEdition')
      this.closeEdit()
    },
    async updateComment (comment, oldComment) {
      const query = this.getUpdateQuery(comment.__typename)
      const resultName = this.getUpdateResultName(comment.__typename)
      const withImage = comment.imageUrl !== oldComment.imageUrl
      const payload = {
        commentId: comment.id,
        text: comment.text,
        imageChanged: withImage,
        image: withImage && comment.imageUrl ? comment.imageFile : null,
      }
      await this.$graphqlMutate(
        query, payload,
      ).then(response => {
        this.editableComment = response[resultName]
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    getUpdateQuery (typename) {
      return typename === 'InitialComment' ? updateInitialComment : updatePmComment
    },
    getUpdateResultName (typename) {
      return typename === 'InitialComment' ? 'updateInitialComment' : 'updatePmComment'
    },
    async createComment (comment, commentedPage) {
      const payload = {
        threadId: this.threadId,
        documentVersionId: this.documentVersionId,
        text: comment.text,
        image: comment.imageUrl ? comment.imageFile : null,
        commentedPage: commentedPage,
      }
      await this.$graphqlMutate(
        createComment, payload,
      ).then(response => {
        this.editableCommentIndex = this.comments.length
        this.editableComment = response.createComment
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    async onDelete (comment) {
      await this.$graphqlMutate(deleteInitialComment, {
        commentId: comment.id,
      }).then(data => {
        if (data.deleteInitialComment.threadDeleted) {
          // no comments left, the thread has been deleted by the backend.
          // inform the super component that the thread does not exist anymore.
          this.$emit('threadDeleted')
        } else {
          this.newCommentAdded = false
          this.comments = this.comments.filter(comment => comment.id !== data.deleteInitialComment.id)
        }
      }).catch(error => {
        this.store.changeNotification({
          type: 'error',
          text: error,
          autoClose: false,
        })
      })
    },
    onActivateLink (isLinkActivated) {
      this.$emit('activateLink', isLinkActivated)
    },
  },
}
</script>
<template>
  <section class="comments">
    <div class="comments-header">
      <span>{{ commentsHeader }}</span>
    </div>
    <v-tooltip
      location="end"
      color="secondary"
    >
      <template v-slot:activator="{ props }">
        <v-fab
          v-if="canAddComment && !newCommentAdded"
          size="small"
          color="primary"
          class="add-btn"
          icon="fas fa-plus"
          v-bind="props"
          @click="onAddClick"
        />
      </template>
      {{ $gettext('Create a new comment') }}
    </v-tooltip>
    <div class="comments-read-only-and-editable">
      <CommentEditable
        v-if="editableCommentShown"
        :comment="editableComment"
        :is-first-comment="isNewThread"
        :is-edition-mode="isEditionMode"
        :current-step="currentStep"
        :is-oman-type="isOmanType"
        :pdf-preview="pdfPreview"
        :commented-page="commentedPage"
        :is-commented-page-clicked-props="isCommentedPageClicked"
        @show-image="showImage"
        @start-edit="startEdit"
        @cancel-edit="cancelEdit"
        @save-edit="onSaveEdit"
        @delete="onDelete"
        @activate-link="onActivateLink"
      />
      <div
        v-for="comment in comments"
        :key="comment.id"
      >
        <CommentReadOnly
          v-if="isReadOnly(comment)"
          class="comment-read-only"
          :comment="comment"
          :is-oman-type="isOmanType"
          @show-image="showImage"
        />
      </div>
      <CommentImagePopup
        v-if="showImagePopup && currentComment"
        :comment="currentComment"
        @close="closeImage"
      />
    </div>
  </section>
</template>
<style lang="scss" scoped>
.comments {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.comments-header {
  color: $white;
  background-color: $secondary;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}
.comments-read-only-and-editable {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px 15px;
  border-radius: 0 0 5px 5px;
  overflow-y: auto;
  background-color: $grey-ultralight;
}
.add-btn {
  position: relative;
  justify-content: flex-end;
  right: 15px;
  top: -10px;
  z-index: 20; // above table and header
}
</style>
